import { Routes, Route, RoutesProps } from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";


const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/home",
  };

  const Home = React.lazy(() => import("../pages/Home/Home"));
  const NotUsingBank = React.lazy(
    () => import("../components/Company_Result/NotUsingBankingServices")
  );
  const BankNotListed = React.lazy(
    () => import("../components/Company_Result/NotUsingBankingServices")
  );
  const CompanyResults = React.lazy(
    () => import("../components/Company_Result/CompanyResults")
  );
  const CompanyResults_1 = React.lazy(
    () => import("../components/Company_Result/CompanyResults_1")
  );
  const ThankYouPage = React.lazy(
    () => import("../pages/ThankYouPage/ThankYouPage")
  );
 
  
  const SurveyContinue = React.lazy(
    () => import("../components/Company_Result/LinkoutOffer")
  );
  const EndSuccessMessage = React.lazy(
    () => import("../components/Success/EndSuccessMessage")
  );
  const BankRecommendationPage = React.lazy(
    () => import("../components/Company_Result/BankRecommendation")
  );
  
  const PostRegistrationSuccess = React.lazy(
    () => import("../components/Success/PostRegistrationSuccess")
  );
  const QuestionSurvey=React.lazy(
    () => import("../components/QuestionSurvey/QuestionSurvey")
  );

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path=""
              element={
                <Suspense fallback={<></>}>
                  <QuestionSurvey />
                </Suspense>
              }
            />
            
             <Route
              path="/:uuid/report"
              element={
                <Suspense fallback={<></>}>
                  <CompanyResults />
                </Suspense>
              }
            />
               <Route
              path="/report"
              element={
                <Suspense fallback={<></>}>
                  <CompanyResults_1 />
                </Suspense>
              }
            />

            <Route
              path="/bank-result/:CoID/:userRating"
              element={
                <Suspense fallback={<></>}>
                  <CompanyResults />
                </Suspense>
              }
            />
            <Route
              path="/result/not-using-bank"
              element={
                <Suspense fallback={<></>}>
                  <NotUsingBank />
                </Suspense>
              }
            />
            <Route
              path="/result/not-using-credit-card"
              element={
                <Suspense fallback={<></>}>
                  <NotUsingBank />
                </Suspense>
              }
            />
            <Route
              path="/result/bank-not-listed"
              element={
                <Suspense fallback={<></>}>
                  <BankNotListed />
                </Suspense>
              }
            />
            <Route
              path="/result/credit-card-not-listed"
              element={
                <Suspense fallback={<></>}>
                  <BankNotListed />
                </Suspense>
              }
            />
            <Route
              path="/result/card-not-listed"
              element={
                <Suspense fallback={<></>}>
                  <BankNotListed />
                </Suspense>
              }
            />
            <Route
              path="/credit-card-result/:CoID/:userRating"
              element={
                <Suspense fallback={<></>}>
                  <CompanyResults />
                </Suspense>
              }
            />
            <Route
              path="/bank-offers"
              element={
                <Suspense fallback={<></>}>
                  {/* <ShowMyOptions /> */}
                  <BankRecommendationPage />
                </Suspense>
              }
            />
            <Route
              path="/credit-card-offers"
              element={
                <Suspense fallback={<></>}>
                  {/* <ShowMyOptions /> */}
                  <BankRecommendationPage />
                </Suspense>
              }
            />
            <Route
              path="/continue"
              element={
                <Suspense fallback={<></>}>
                  <SurveyContinue />
                </Suspense>
              }
            />
            <Route
              path="/bank-recommendation"
              element={
                <Suspense fallback={<></>}>
                  <BankRecommendationPage />
                </Suspense>
              }
            />
            <Route
              path="thankyoupage"
              element={
                <Suspense fallback={<></>}>
                  <ThankYouPage />
                </Suspense>
              }
            />

            
            <Route
              path="postRegSuccess"
              element={
                <Suspense fallback={<></>}>
                  <PostRegistrationSuccess />
                </Suspense>
              }
            />

            <Route
              path="end"
              element={
                <Suspense fallback={<></>}>
                  <EndSuccessMessage />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
