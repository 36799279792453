import { Container, Navbar, Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";

const Footer = () => {
  return (
    <>
      <footer className="site-footer">
        <Container className="header-nav">
          <Row>
            <Col lg={12} className="text-center">
              <div className="copyright">©My Values Media, LLC - 2024</div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
