import { Container, Navbar, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Logo from "../../assets/images/logo.svg";
import Logo from "../../assets/images/newLogo.svg";

const Header = () => {
  return (
    <>
      <header className="site-header">
        <Container className="header-nav">
          <Row>
            <Col lg={12} className="text-center">
              <Navbar.Brand className="text-center">
                <Link to="/">
                  <img
                    src={Logo}
                    width={321}
                    height={65}
                    alt="My Money My Values"
                  />
                </Link>
              </Navbar.Brand>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
export default Header;
